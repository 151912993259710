import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  Gutter,
  EmptyLayout,
  SmallHeader,
  Paragraph,
  useApi,
} from '@axiom/ui';
import { AfcSubmissionCandidateOpportunity } from '@axiom/validation';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountConst, ClientRequestConst } from '@axiom/const';

import FaceCardsGraphic from '../../public/svg/face-cards.svg';
import { AccountSubmissionsLegacyApi } from '../../api/account-submissions-legacy-api';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateCardHeader } from '../CandidateCardHeader/CandidateCardHeader';
import { EngagementPrompt } from '../EngagementPrompt/EngagementPrompt';
import { AccountLegacyApi } from '../../api/account-legacy-api';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { EngagementPassModal } from '../EngagementPassModal/EngagementPassModal';
import { InstantMatchesApi } from '../../api/instant-matches-api';

import { useMyEngagementsInterviewSubmissionData } from './useMyEngagementsInterviewSubmissionData';
import { MyEngagementsInterviewTalentProfile } from './MyEngagementsInterviewTalentProfile';

export const MyEngagementsItemInterviewTalent = () => {
  const navigate = useNavigate();
  const [showEngageConfirmPrompt, setShowEngageConfirmPrompt] = useState(null);
  const [showPassConfirmPrompt, setShowPassConfirmPrompt] = useState(null);
  const params = useParams();
  const { accountId, submissionId } = params;
  const { candidateOpps, currentInterviewSubmission } =
    useMyEngagementsInterviewSubmissionData({ accountId, submissionId });
  const { user } = useUserAccountData();

  const allGroupedExperiences = useApi(
    ...candidateOpps.map(({ candidate: { id } }) =>
      CandidateApi.readCandidateGroupedExperiences(id, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const handleEngageClick = (
    candidateOpp?: AfcSubmissionCandidateOpportunity
  ) => {
    setShowEngageConfirmPrompt(candidateOpp);
  };
  const handlePassClick = (
    candidateOpp?: AfcSubmissionCandidateOpportunity
  ) => {
    setShowPassConfirmPrompt(candidateOpp?.candidate);
  };

  const refreshData = (
    candidate: AfcSubmissionCandidateOpportunity['candidate']
  ) => {
    Promise.all([
      AccountLegacyApi.refreshAccount(accountId, {
        expand: [AccountConst.Expansions.accountTeamUserInfo],
      }),
      AccountSubmissionsApi.refreshAccountSubmissionDetails(
        accountId,
        submissionId
      ),
      AccountSubmissionsLegacyApi.refreshCandidateAccountSubmissionDetails(
        accountId,
        submissionId,
        candidate.id
      ),
      AccountSubmissionsApi.refreshAccountSubmissionDetails(
        accountId,
        submissionId
      ),
    ]);
  };

  const handleSubmitPassModal = (
    formData: {
      rejectionLossCode: string;
      notes?: string;
    },
    candidate?: AfcSubmissionCandidateOpportunity['candidate']
  ) => {
    const body = {
      submissionId,
      candidateId: candidate.id,
      clientUserId: user.id,
      requestType: ClientRequestConst.RequestTypes.ClientPassed,
      rejectionLossCodes: [formData.rejectionLossCode],
      notes: formData.notes,
    };

    return AccountLegacyApi.updateClientRequest(accountId, body).then(() => {
      refreshData(candidate);
      setShowPassConfirmPrompt(null);
    });
  };

  const invalidateInstantMatches = () => {
    // Instant Matches are cleared by one-off-listener, this gives time for updates to complete
    return new Promise<void>(resolve => {
      setTimeout(async () => {
        await InstantMatchesApi.refreshInstantMatches(submissionId);
        resolve();
      }, 1500);
    });
  };

  const handleOnConfirmClick = async (
    candidate: AfcSubmissionCandidateOpportunity['candidate']
  ) => {
    const body = {
      submissionId,
      candidateId: candidate.id,
      clientUserId: user.id,
      requestType: ClientRequestConst.RequestTypes.EngageRequested,
    };

    await AccountLegacyApi.updateClientRequest(accountId, body);
    setShowEngageConfirmPrompt(null);
    await refreshData(candidate);
    await invalidateInstantMatches().then(() => navigate('../matches'));
  };

  return (
    <div data-test="interview-talent-tab">
      {candidateOpps.length === 0 && (
        <Card name="INTERVIEW_EMPTY_CARD">
          <CardSection>
            <EmptyLayout
              graphic={
                <FaceCardsGraphic
                  width="117px"
                  height="91px"
                  data-test="face-cards-graphic"
                />
              }
              graphicPosition="left"
            >
              <SmallHeader>Upcoming interviews will appear here</SmallHeader>
              <Gutter bottom="16px" />
              <Paragraph>
                Let us know you want to interview talent on the matches tab.
                Upcoming interviews will appear here where you can provide
                feedback and continue to move them through the hiring process.
              </Paragraph>
            </EmptyLayout>
          </CardSection>
        </Card>
      )}
      {candidateOpps.map((candidateOpp, index) => {
        return (
          <Gutter
            bottom="16px"
            key={`interview-${candidateOpp.id}-${index + 1}`}
          >
            <Card>
              <CardHeader>
                <CandidateCardHeader
                  candidateOpp={candidateOpp}
                  onEngage={() => handleEngageClick(candidateOpp)}
                  submission={currentInterviewSubmission}
                  onPass={() => handlePassClick(candidateOpp)}
                />
              </CardHeader>
              <CardSection>
                <MyEngagementsInterviewTalentProfile
                  candidate={candidateOpp.candidate}
                  groupedExperiences={allGroupedExperiences[index]}
                  submission={currentInterviewSubmission}
                  practiceAreas={practiceAreas}
                  taxonomy={taxonomy}
                />
              </CardSection>
            </Card>
          </Gutter>
        );
      })}
      {!!showPassConfirmPrompt && (
        <EngagementPassModal
          candidate={showPassConfirmPrompt}
          closeModal={() => handlePassClick(null)}
          submitModal={(data: { rejectionLossCode: string; notes?: string }) =>
            handleSubmitPassModal(data, showPassConfirmPrompt)
          }
        />
      )}
      {!!showEngageConfirmPrompt && (
        <EngagementPrompt
          candidateOpp={showEngageConfirmPrompt}
          onClose={() => handleEngageClick(null)}
          onConfirm={handleOnConfirmClick}
        />
      )}
    </div>
  );
};
