import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Grid,
  GridColumn,
  GridRow,
  Suspense,
  Loader,
  ClientEngagementNoTalentMessage,
  ClientEngagementSelfServiceNoTalentMessage,
} from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { EnvUtil } from '../../utils/env-util';
import { useEngagementTalentData } from '../../hooks/useEngagementTalentData';

import { EngagementTalentList } from './EngagementTalentList';

export const EngagementTalent = () => {
  const navigate = useNavigate();
  const {
    user,
    accountId,
    candidateOpp,
    candidateOppFromSlug,
    groupedCandidateOpps,
    submission,
    invalidSubmissionRequest,
    talentSlug,
  } = useEngagementTalentData();
  const currentSubmissionCandidatesIsNotEmpty = groupedCandidateOpps.length > 0;
  const multipleRolesPresentInPositions = groupedCandidateOpps.length > 1;

  const noResultsRender = () => {
    if (submission.accountType === AccountConst.Types.Direct) {
      return <ClientEngagementSelfServiceNoTalentMessage />;
    }
    return (
      <ClientEngagementNoTalentMessage
        newEngagementUrl={EnvUtil.hubspotTalentFinder}
      />
    );
  };

  useEffect(() => {
    /**
     * If talentSlug is malformed removed it
     * and show the first talent in the list
     * */
    if (groupedCandidateOpps && talentSlug && !candidateOppFromSlug) {
      navigate('..');
    }
  }, [groupedCandidateOpps, talentSlug]);

  useEffect(() => {
    if (invalidSubmissionRequest) {
      if (user?.id) {
        navigate(`/my-axiom/account/${accountId}`);
      } else {
        navigate(`/404`);
      }
    }
  }, [invalidSubmissionRequest]);

  return invalidSubmissionRequest ? null : (
    <div data-test="SUBMISSION">
      <Suspense fallback={<Loader type="bars" />}>
        {currentSubmissionCandidatesIsNotEmpty ? (
          <Grid>
            <GridRow name="talent-detail-row">
              <GridColumn largeScreenWidth={4} tabletWidth={4} mobileWidth={12}>
                {groupedCandidateOpps.map(group => (
                  <div key={group.title}>
                    <EngagementTalentList
                      hasMultipleRoles={multipleRolesPresentInPositions}
                      candidateOpportunities={group.data}
                      selectedCandidateId={candidateOpp?.candidate?.id}
                      submission={submission}
                      title={group.title}
                    />
                  </div>
                ))}
              </GridColumn>
              <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
                <Outlet />
              </GridColumn>
            </GridRow>
          </Grid>
        ) : (
          noResultsRender()
        )}
      </Suspense>
    </div>
  );
};
