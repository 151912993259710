import React, { useState } from 'react';
import {
  Button,
  CardSection,
  colors,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Layout,
  LayoutBleed,
  Visible,
  DarkTheme,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import EndCardImage from '../../public/images/DiscoverTransparentBkg.png';
import { generateAHQOpportunityURL } from '../../utils/link-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';
import { EngagementsShareModal } from '../EngagementsShareModal/EngagementsShareModal';
import { EngagementsShareContextMenu } from '../EngagementsShareContextMenu/EngagementsShareContextMenu';
import { useCurrentSubmissionData } from '../../hooks/useCurrentSubmissionData';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';

export const EngagementHeader = () => {
  const params = useParams();
  const [showShareModal, setShowShareModal] = useState(false);
  const { account, user } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({ account });
  const submission = params?.submissionId
    ? allSubmissions.find(sub => sub.submissionId === params.submissionId)
    : allSubmissions?.[0];
  const { accountId, currentSubmission, invalidSubmissionRequest } =
    useCurrentSubmissionData({
      account,
      submissionId: submission?.submissionId,
    });
  return invalidSubmissionRequest ? null : (
    <>
      <DarkTheme>
        <CardSection
          background={{
            image: EndCardImage,
            position: 'right center',
            color: colors.controlPrimary,
          }}
        >
          <Layout name="CLIENT_ACTIONS_BANNER">
            <LayoutBleed>
              <Gutter vertical="24px">
                <Grid stackable>
                  <GridRow gutterBottom="16px">
                    <GridColumn width={12}>
                      <Button
                        to="../../engagements"
                        variation="minimal"
                        pattern="secondary"
                        name="BACK_TO_ENGAGEMENTS"
                        icon="arrow-left"
                        iconPosition="left"
                        inverse
                        onClick={() => {
                          AccountSubmissionsApi.refreshAccountSubmissionDetails(
                            account.id,
                            submission.id
                          );
                        }}
                      >
                        All Engagements
                      </Button>
                      <Visible only="mobile">
                        <Gutter bottom="8px" />
                      </Visible>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn
                      largeScreenWidth={10}
                      tabletWidth={10}
                      mobileWidth={12}
                    >
                      <LargeHeader>{currentSubmission.jobName}</LargeHeader>
                      <Visible only="mobile">
                        <Gutter bottom="16px" />
                      </Visible>
                    </GridColumn>
                    <GridColumn
                      largeScreenWidth={2}
                      tabletWidth={2}
                      mobileWidth={12}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <EngagementsShareContextMenu
                        user={user}
                        onClickInviteToAccount={() =>
                          setShowShareModal(!showShareModal)
                        }
                        onClickOpenInAhq={() => {
                          window.open(generateAHQOpportunityURL(submission.id));
                        }}
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Gutter>
            </LayoutBleed>
          </Layout>
        </CardSection>
      </DarkTheme>
      {!!showShareModal && (
        <EngagementsShareModal
          accountName={account.calculatedName}
          accountId={accountId}
          currentSubmission={currentSubmission}
          onClose={() => setShowShareModal(!showShareModal)}
        />
      )}
    </>
  );
};
