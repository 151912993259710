import React, { useState } from 'react';
import {
  CardSection,
  colors,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Layout,
  LayoutBleed,
  Visible,
  DarkTheme,
  useApiWithErrors,
  ApiError,
  GoneError,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import EndCardImage from '../../public/images/DiscoverTransparentBkg.png';
import { PublicEngagementToLoginModal } from '../PublicEngagementToLoginModal/PublicEngagementToLoginModal';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { EngagementsShareContextMenu } from '../EngagementsShareContextMenu/EngagementsShareContextMenu';

export const PublicEngagementHeader = () => {
  const [displayLoginModal, setDisplayLoginModal] = useState(false);
  const { accountId, submissionId } = useParams();
  let invalidSubmissionRequest = false;

  const [response] = useApiWithErrors(
    AccountSubmissionsApi.readAccountSubmissionDetails(accountId, submissionId)
  );

  if (response instanceof ApiError) {
    if (response instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw response;
    }
  }
  const { data: submission = {} } = response;

  return invalidSubmissionRequest ? null : (
    <>
      <DarkTheme>
        <CardSection
          background={{
            image: EndCardImage,
            position: 'right center',
            color: colors.controlPrimary,
          }}
        >
          <Layout name="CLIENT_ACTIONS_BANNER">
            <LayoutBleed>
              <Gutter vertical="24px">
                <Grid stackable>
                  <GridRow>
                    <GridColumn
                      largeScreenWidth={10}
                      tabletWidth={10}
                      mobileWidth={12}
                    >
                      <LargeHeader name="CLIENT_NAME">
                        {submission.jobName}
                      </LargeHeader>
                      <Visible only="mobile">
                        <Gutter bottom="16px" />
                      </Visible>
                    </GridColumn>
                    <GridColumn
                      largeScreenWidth={2}
                      tabletWidth={2}
                      mobileWidth={12}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <EngagementsShareContextMenu
                        onClickInviteToAccount={() =>
                          setDisplayLoginModal(true)
                        }
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Gutter>
            </LayoutBleed>
          </Layout>
        </CardSection>
      </DarkTheme>
      {!!displayLoginModal && (
        <PublicEngagementToLoginModal
          onClose={() => setDisplayLoginModal(false)}
        />
      )}
    </>
  );
};
