import { useApi } from '@axiom/ui';
import {
  Account,
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
} from '@axiom/validation';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { SubmissionsUtil } from '../../utils/submissions-util';

interface Args {
  accountId?: Account['id'];
  submissionId?: AfcSubmission['id'];
}

export const useMyEngagementsInterviewSubmissionData = ({
  accountId: argAccountId,
  submissionId: argSubmissionId,
}: Args = {}) => {
  const { submissionId: urlSubmissionId, accountId: urlAccountId } =
    useParams();
  const accountId = urlAccountId ?? argAccountId;
  const submissionId = urlSubmissionId ?? argSubmissionId;

  const [{ data: submissionData } = { data: {} }] = useApi(
    accountId &&
      submissionId &&
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId
      )
  );

  const currentInterviewSubmission: AfcSubmission =
    SubmissionsUtil.getInterviewingCandidates(submissionData);
  const candidateOpps: AfcSubmissionCandidateOpportunity[] =
    currentInterviewSubmission?.positions?.flatMap(
      position => position.candidateOpportunities
    );

  const interviewCount = candidateOpps?.length ?? 0;
  return {
    candidateOpps,
    currentInterviewSubmission,
    interviewCount,
  };
};
