import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  Well,
  Layout,
  LayoutItem,
  Gutter,
  SmallHeader,
} from '@axiom/ui';

import YellowSweater from '../../public/svg/yellow-sweater.svg';
import { PublicEngagementUrlUtil } from '../../utils/public-engagement-url-util';
import { EnvUtil } from '../../utils/env-util';

export const PublicEngagementToLoginModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const location = useLocation();
  const handleOnClickConfirm = () => {
    const relayState = `${window.location.origin}${PublicEngagementUrlUtil(
      location.pathname
    ).fromPublic()}`;
    const loginUrl = `${EnvUtil.loginUrl}/?RelayState=${relayState}`;
    window.location.assign(loginUrl);
  };
  return (
    <Modal name="LOGIN_PROMPT">
      <ModalHeader name="HEADER" onClose={onClose}>
        Sign in for full access
      </ModalHeader>
      <ModalSection>
        <Well>
          <Layout position="middle">
            <LayoutItem>
              <SmallHeader>
                Sign in to view more details, schedule interviews and take
                action on the talent matches.
              </SmallHeader>
            </LayoutItem>
            <LayoutItem>
              <YellowSweater width="256px" height="168px" />
            </LayoutItem>
          </Layout>
        </Well>
        <Gutter bottom="16px" />
        Once signed in you will be redirected to your Axiom client account with
        full access to this talent submission.
      </ModalSection>
      <ModalFooter>
        <Button name="GO_TO_LOGIN" onClick={handleOnClickConfirm}>
          Sign in to your account
        </Button>
      </ModalFooter>
    </Modal>
  );
};
